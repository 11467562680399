<template>

	<n-card style="width: 100%; height: 80vh;">
		<n-spin :show="loading">

			<n-tabs type="line">
				<n-tab-pane name="Top Money">
					<n-scrollbar style="height: 70vh; padding-right: 1vw;">
						<n-list>
							<n-list-item v-for="(data, key) in topMoney">
								<n-thing 
									:title="`#${key+1} ${data.username} [${data.id}]`"
									:title-extra="data.total.toLocaleString('en-US', {style: 'currency', currency: 'USD', maximumFractionDigits: 0})"
								/>
							</n-list-item>
						</n-list>
					</n-scrollbar>
				</n-tab-pane>

				<n-tab-pane name="Top FireCoins">
					<n-scrollbar style="height: 70vh; padding-right: 1vw;">
						<n-list>
							<n-list-item v-for="(data, key) in topFireCoins">
								<n-thing 
									:title="`#${key+1} ${data.username} [${data.id}]`"
									:title-extra="data.fireCoins.toLocaleString('en-US', {maximumFractionDigits: 0})"
								/>
							</n-list-item>
						</n-list>
					</n-scrollbar>
				</n-tab-pane>

				<n-tab-pane name="Top Bani Nedeclarabili">
					<n-scrollbar style="height: 70vh; padding-right: 1vw;">
						<n-list>
							<n-list-item v-for="(data, key) in topDirtyMoney">
								<n-thing 
									:title="`#${key+1} User Id ${data._id}`"
									:title-extra="data.dirtyMoney.toLocaleString('en-US', {maximumFractionDigits: 0})"
								/>
							</n-list-item>
						</n-list>
					</n-scrollbar>
				</n-tab-pane>

				<n-tab-pane name="Top Seminte Marijuana">
					<n-scrollbar style="height: 70vh; padding-right: 1vw;">
						<n-list>
							<n-list-item v-for="(data, key) in topWeedSeeds">
								<n-thing 
									:title="`#${key+1} User Id ${data._id}`"
									:title-extra="data.weedSeeds.toLocaleString('en-US', {maximumFractionDigits: 0})"
								/>
							</n-list-item>
						</n-list>
					</n-scrollbar>
				</n-tab-pane>
			</n-tabs>
			
		</n-spin>
	</n-card>

</template>


<script>

import PostService from '../PostService'

export default {

	data() {
		return {
			topMoney: [],
			topFireCoins: [],
			topDirtyMoney: [],
			topWeedSeeds: [],
			loading: true
		}
	},
	async mounted() {
		const res = await PostService.getTopPlayers();
		
		this.topMoney = res[0];
		this.topFireCoins = res[1];
		this.topDirtyMoney = res[2];
		this.topWeedSeeds = res[3];

		this.loading = false; 
	}

}

</script>